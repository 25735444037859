#featured-about {
    background: #000000;
    padding-bottom: 8%;
    padding-top: 8%;
}
.header-title-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.title {
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 56px;
    font-family: saira;
    margin: 0;
    position: relative;
}
.title:before {
    content: '';
    width: 10px;
    height: 10px;
    background: #ffffff;
    position: absolute;
    bottom: -4px;
    left: 50%;
    border-radius: 100%;
    transform: translate(-50%, 15%);
}
.title-line {
    height: 1px;
    width: 496px;
    background: #ffffff;
    position: relative;
}

.title-line:before, 
.title-line:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #ffffff;
    position: absolute;
    border-radius: 100%;
    bottom: -4px;
}
.title-line:before {
    left: 0;
}
.title-line:after {
    right: 0;
}

@media(max-width: 991px) {
    .header-title-container .title {
        font-size: 46px;
    }
    .header-title-container .title-line {
        width: 412px;
    }
}

@media(max-width: 575px) {
    .header-title-container .title {
        font-size: 30px
    }
    .header-title-container .title-line {
        width: 260px;
    }
    .header-title-container .title:before, 
    .title-line:before, 
    .title-line:after {
        height: 8px;
        width: 8px;
    }
}

@media(width: 320px) {
    .header-title-container .title-line {
        width: 250px;
    }
}
