#teamWork {
    padding: 60px 0;
}
.title-team {
    display: flex;
    align-items: center;
    justify-content: center;
}
.title-team .line-title {
    width: 170px;
    height: 1px;
    background: #000000;
    position: relative;
}
.title-team .line-title.left {
    margin-left: 10px;
}
.title-team .line-title.right {
    margin-right: 10px;
}
.title-team .line-title.left:before, 
.title-team .line-title.right:after {
    content: '';
    height: 8px;
    width: 8px;
    background: #000000;
    border-radius: 50%;
    position: absolute;
    top: -3.5px;
}
.title-team .line-title.right:after {
    content: '';
    right: 0;
}
.title-team h2 {
    font-size: 36px;
    font-family: saira;
    font-weight: 600;
    margin: 0 16px;
}
.title-team .btn-teamSlider {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    border-color: unset;
    height: 24px;
    color: transparent !important;
}
.title-team .btn-teamSlider:not(:disabled):active:focus, 
.title-team .btn-teamSlider:active, 
.title-team .btn-teamSlider:focus {
    box-shadow: unset;
    border-color: unset !important;
}

#teamWork .owl-carousel .owl-stage {
    display: flex;
    align-items: flex-end;
}
.memberItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.info-member {
    opacity: 0;
    transition: opacity .8s;
}
.active.center .info-member {
    opacity: 1;
}
.active.center .line-photo {
    opacity: 0;
}
.info-member h3 {
    padding: 4px 15px;
    color: #ffffff;
    font-family: saira;
    margin: 0;
    font-size: 26px;
}
.cargo {
    font-family: muli;
    color: #171717;
    font-size: 17px;
    margin-bottom: 10px;
}
.photoMember {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    height: 400px;
    width: 300px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.active.center .photoMember {
    height: 470px;
}
.memberItem .social-items {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.memberItem .social-items img {
    height: 35px;
    width: 35px;
}
.memberItem .line-photo {
    height: 4px;
    width: 90%;
    margin-bottom: 10px;
    transition: opacity .8s;
}

@media(max-width: 991px) {
    #procesos .Tlabel>h2, 
    #estadisticas .Tlabel>h2 {
        font-size: 50px;
    }
}

@media(max-width: 768px) {
    .title-team .line-title {
        width: 90px;
    }
    .title-team h2 {
        font-size: 30px;
    }
    .memberItem .line-photo {
        display: none;
    }
}

@media(max-width: 575px) {
    #procesos .Tlabel > h2, 
    #estadisticas .Tlabel > h2 {
        font-size: 30px;
    }
    .active.center .photoMember {
        height: 400px;
        width: 260px;
    }
    .info-member h3 {
        font-size: 22px;
    }
    .title-team h2 {
        font-size: 26px;
    }
}