.menu-principal-container {
    background-image: radial-gradient(circle at 72% 37%, gray -101%, black 32%);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.4s;
    pointer-events: none;
    min-height: 560px;
}
.show-menu-principal {
    opacity: 1;
}
.unable-menu-items {
    pointer-events: none !important;
}
.menu-principal-container .image-container {
    background: url('../../assets/images/img-menu-principal.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.menu-principal-container .menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-principal-container .menu-container .menu-principal {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.menu-container .menu-principal .menu-principal-item {
    color: white;
    display: flex;
    align-items: center;
}
.menu-container .menu-principal .menu-principal-item a {
    font-size: 25px;
    pointer-events: auto;
}
.menu-container .menu-principal .menu-principal-item .number-menu {
    margin-right: 10px;
    font-size: 12px;
}
.menu-container .menu-principal .menu-principal-item .linea-menu {
    background: white;
    width: 80px;
    height: 1px;
    position: relative;
    margin-right: 20px;
}
.menu-container .menu-principal .menu-principal-item .linea-menu:after {
    content: "";
    width: 6.5px;
    height: 6.5px;
    background: white;
    position: absolute;
    border-radius: 50%;
    right: -0.5px;
    bottom: -3px;
}

@media (max-width:360px){
    .menu-principal-container .menu-container .menu-principal {
       
        height: 54%;
        
        justify-content: space-around;
    }
    .menu-container .menu-principal .menu-principal-item {
       
        padding-right: 40px;
    }
    .menu-container .menu-principal .menu-principal-item .linea-menu {
        
        width: 40px;
        height: 1px;
        margin-right: 13px;
        
    }
    .menu-principal-container .image-container {
        
        background-size: 89% 35%;
       
        background-position: 33%;
    }

}