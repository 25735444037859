.seccion-contenido {
	padding-top: 75px !important;
    padding-bottom: 75px !important;
    padding: 320px;
    background: url('../../assets/images/Reticula-1.svg');
    background-size: 100% auto !important;
    background-repeat: repeat;      
}
.seccion-contentido .row {
	max-width: 1100px;
}
.seccion-contenido h1,
.seccion-contenido h2,
.seccion-contenido h3,
.seccion-contenido h4,
.seccion-contenido h5,
.seccion-contenido p{
	font-family: muli;
}

.seccion-contenido figure {
	display: flex;
	justify-content: center;
	margin-top: 60px;
    margin-bottom: 60px;
}
@media(max-width: 991px){
	.seccion-contenido {
		padding: 150px; 
	}
	.seccion-contenido figure img {
		width: 100%;
	}
}
@media(max-width: 767px){
	
	.seccion-contenido{
	    padding: 110px;
	}
	.seccion-contenido figure {
	    display: flex;
	    justify-content: center;
	    margin-top: 60px !important;
	    margin-bottom: 60px !important;
	    margin: 0 auto;
	    max-width: 575px;
	}
}
@media(max-width: 575px){
	.seccion-contenido {
		padding: 80px;
	}
}