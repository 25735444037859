#primeraSeccion {
    background: url('../../assets/images/Reticula-1.svg');
    background-size: 100% auto !important;
    background-repeat: repeat;
}

@media (max-width:900px){
    #primeraSeccion {
        background-size: 100% auto !important   ;
    }
}