.Tlabel{
    position: relative;
}
.title-section-container{
    border-bottom: solid 1px black;
    position: absolute;
    width: 0;
    top: 39px;
    left: 0;
    transition: width 2s;
}
.Tlabel-width {
    width: 49px !important;
}
.title-section-container::before{
    font-size: 24px;
    color: black;
    content: "\2022";
    position: absolute;
    top: -20px;
    width: 9px;
    left: -4px;
}
.Tlabel>h2 {
    padding-left: 43px;
    font-family: saira;
    font-size: 60px;
    color: #333333;
}
.Tcontent>p{
    width: 90%  ;
    margin-left: 50px;
    font-family: muli;
    font-size: 17px;
    color: #4D4D4D;
}
.divServiciosTld {
    
    max-width: 90%;
}
@media (max-width:576px) {
    .Tlabel>h2 {
        font-size: 36px;
        padding-top: 18px;
    }

    .title-section-container:before {
        font-size: 24px;
        color: #000;
        content: "\2022";
        position: absolute;
        top: -16.7px;
        width: 9px;
        left: -4px;
    }
}
@media (max-width:360px) {
    .title-section-container:before {
        top: -16.7px;
    }
}