section.options {
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 3;
    padding: 35px 0;
    pointer-events: none;
    top: 0;
}
section.options > div.container-fluid {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
}
.top-options {
    display: flex;
    justify-content: space-between;
}
.top-options .logo-link {
    pointer-events: auto;
}
.top-options .logo-options {
    height: 74px;
}
.top-options .logo-black {
    display: none;
}
div.burger-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 36px;
    cursor: pointer;
    pointer-events: auto;
}
div.burger-container span.burger-item {
    background: white;
    height: 5px;
    width: 57px;
    display: block;
    border-radius: 1px;
}
div.burger-container span.burger-item:nth-child(3) {
    width: calc(57px / 2);
}
.bottom-options {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.bottom-options > a {
    pointer-events: auto;
}
.bottom-options > a:hover {
    color: white;
}
.bottom-options li.menu-social-container {
    list-style: none;
    cursor: pointer;
    position: relative;
    pointer-events: auto;
}
.bottom-options li.menu-social-container ul.menu-social {
    padding: 0;
    padding-left: 5px;
    margin: 0;
    position: absolute;
    bottom: 0px;
    transition: all .5s ease;
    opacity: 0;
    visibility: hidden;
    list-style: none;
}
.bottom-options li.menu-filter-container ul.menu-social:before {
    content: "";
    width: 1px;
    height: 140px;
    background: white;
    position: absolute;
    right: 85px;
    top: -6px;
}
.bottom-options li.menu-social-container ul.menu-social:before {
    content: "";
    width: 1px;
    height: 126px;
    background: white;
    position: absolute;
    right: 85px;
    top: -6px;
}
.bottom-options li.menu-social-container ul.menu-social:after {
    content: "";
    width: 6.5px;
    height: 6.5px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: -11px;
    right: 82px;
    transition: background 1s;
}
.black::after, .black::before{
    background: black !important;
}

.bottom-options .menu-social-container:hover ul.menu-social {
    bottom: 40px;
    opacity: 1;
    visibility: visible;
}
.bottom-options ul.menu-social li.menu-social-item {
    position: relative;
}
.bottom-options ul.menu-social li.filter-item {
    position: relative;
    margin: 5px;
}
.bottom-options ul.menu-social li.menu-social-item:before {
    content: "";
    width: 6.5px;
    height: 6.5px;
    position: absolute;
    border-radius: 50%;
    left: -18px;
    top: 9px;
}
.filter-item .menu-principal-link {
    position: relative;
    right: 100px;  
    color: white;
}
.bottom-options ul.menu-social li[data-social="facebook"]:before {
    background: #405597;
}
.bottom-options ul.menu-social li[data-social="twitter"]:before {
    background: #65B3EB;
}
.bottom-options ul.menu-social li[data-social="linkedin"]:before {
    background: #5595CD;
}
.bottom-options ul.menu-social li[data-social="youtube"]:before {
    background: #DC433A;
}
.bottom-options ul.menu-social li[data-social="instagram"]:before {
    background: radial-gradient(circle at 5% 95%, #fdf497 0%, #fdf497 0%, #fd5949 42%,#d6249f 54%,#285AEB 90%);
}
@media(max-width: 575px){
    section.options {
        padding: 10px 0;
    }
    .top-options .logo-options {
        height: 45px;
    }
    div.burger-container {
        height: 15px;
    }
    div.burger-container span.burger-item {
        height: 2px;
        width: 40px;
    }

}