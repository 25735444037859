.statistics-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.statistics-item .title-statistic {
    font-size: 76px;
    font-weight: bold;
    position: relative;
}

.statistics-item .title-statistic:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
}

.statistics-item:nth-child(1) .title-statistic {
    -webkit-text-stroke: 2px #d88887;
    color: rgba(216, 136, 135, 0.75);
}
.statistics-item:nth-child(1) .title-statistic:after {
    background: #d88887;
}

.statistics-item:nth-child(2) .title-statistic {
    -webkit-text-stroke: 2px #75db78;
    color: rgba(117, 219, 120, 0.75);
}
.statistics-item:nth-child(2) .title-statistic:after {
    background: #75db78;
}

.statistics-item:nth-child(3) .title-statistic {
    -webkit-text-stroke: 2px #ffdd84;
    color: rgba(255, 221, 132, 0.75);
}
.statistics-item:nth-child(3) .title-statistic:after {
    background: #ffdd84;
}

.statistics-item:nth-child(4) .title-statistic {
    -webkit-text-stroke: 2px #74e6fe;
    color: rgba(116, 230, 254, 0.75);
}
.statistics-item:nth-child(4) .title-statistic:after {
    background: #74e6fe;
}

.statistics-item .content-statistic {
    font-family: muli;
    font-size: 17px;
    color: #4D4D4D;
}