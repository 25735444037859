.featured {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}
.shade {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    background: black;
    opacity: 0.5;
}
.featured-video {
    min-height: 100%;
    min-width: 100%;
    transition: opacity 0.8s;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    background: transparent url('../../assets/images/logooruga.gif');
    background-position: center;
    background-repeat: no-repeat;
}
.featured-img {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}
.featured-title {
    position: absolute;
    z-index: 1;
    color: #fff;
    top: 30%;
    left: 50%;
    width: 75%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    font-family: muli;
    font-size: 50px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    -webkit-animation: flicker 12s infinite;
}
.featured-img-father {
    position: relative;
}
.video-ended {
    background: url('../../assets/images/footer-background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.video-ended .estruct-featured {
    background: url('../../assets/images/Reticulas-featured.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.featured-texto-corto{
    position: absolute;
    top: 60%;
    color: white;
    left: 50%;
    transform: translateX(-50%);
    width: 35%;
    text-align: center;
    font-family: muli;
    font-size: 20px;
}
.video-ended .logo-featured {
    height: 200px;
}
@-webkit-keyframes flicker
{
    0% {opacity:1;}
    30% {opacity:0;}
    30.5% {opacity:.5;}
    31% {opacity:0;}
    31.5% {opacity:.5;}
    32% {opacity:1;}
}
@media(max-width: 991px){
    .featured-title {
        font-size: 45px;
    }
}
@media(max-width: 767px){
    .featured-title {
        font-size: 35px;
    }
    .featured-texto-corto {
        position: absolute;
        top: 55%;
        color: white;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 50%;
        text-align: center;
        font-family: muli;
        font-size: 20px;
    }  
}
@media(max-width: 575px){
    .featured-title {
        font-size: 25px;
        top: 35%;
        width: 85%;
    }
    .featured-texto-corto {
        width: 75%;
    }
}