@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.divTestimoniosTld {
    
    max-width: 90%;
}
.img-testimonial {
	width: 15em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);	
    padding: 15px;
    
}


.contentTest {
    position: relative;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgContentT{
    background: url('../../assets/images/CRIRCLE-11-11.png');
        width: 100%;
        height: 430px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: relative;
 
}

.client-testimonial {
	position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
    padding-left: 15%;	
}
.client-testimonial h3 {
    font-family: saira;
    font-weight: 600;
    font-size: 31px;
}
.client-testimonial h4{
    font-family: saira;
}
.client-testimonial p{
    font-family: muli;
}
#secTestimonios {
    padding-bottom: 2rem!important;
	padding-top: 2rem!important;
}
@media (max-width:1250px){
    .img-testimonial {
        width: 19vw;
        height: initial; 
        
    }
}
@media (max-width:767px){
    .img-testimonial {
        width: 38vw;
        height: initial; 
        
    }
  
    .slick-list {
        height: auto  !important;    
    }
    .contentTest {
        
        height: auto;
        
    }
    

}