.seccion-VideoPost {
	height: 100vh;
	width: 100%;
	position: relative;
}
.embedBlog{
    height: 65%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 65%;
    left: 50%;
}
.play-pause {
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	border: 2px solid white;
	background: rgba(255, 255, 255, 0.3);
	width: 100px;
	height: 100px;
	cursor: pointer;
	opacity: 1;
	transition: opacity 0.6s;
}
.play-button {
	width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-left: 36px solid white;
    border-bottom: 20px solid transparent;
    position: absolute;
    left: 50%;
	top: 50%;
	transform: translate(-43%, -50%);
	opacity: 1;
	transition: opacity 0.6s;
}
.play .play-button {
	opacity: 0;
}
.pause-button {
	height: 36px;
    width: 14px;
    background: white;
    border-radius: 2px;
    position: absolute;
    left: 47%;
    top: 50%;
	transform: translate(-100%, -50%);
	opacity: 0;
	transition: opacity 0.6s;
}
.pause-button:after {
    content: "";
    height: 36px;
    width: 14px;
    background: white;
    border-radius: 2px;
    position: absolute;
    left: 26px;
    transform: translate(-50%, -50%);
    top: 50%;
}
.play .pause-button {
	opacity: 1;
}
.hide-button {
	opacity: 0;
}