.imgBlog {
	width: 100%;
	height: 250px;    
}
.item-blog {
    cursor: pointer;
}
.space-blog {
	padding-top: 75px !important;
    padding-bottom: 75px !important;
    padding: 19%;
    background: url('../../assets/images/Reticula-1.svg');
    background-size: 100% auto !important;
    background-repeat: repeat;
}
.pBlog {
    font-family: muli;
    font-size: 15px;
}
.dateBlog {
	float: right;
	margin: 0;
	font-family: muli;	    
}
.tltBlog {
	font-family: muli;
    font-weight: 600;
    font-size: 21px;
    margin-top: 10px;
}
.linkBlog,.linkBlog:hover{
    color: #2d2d2d;
    text-decoration: none;
}
@media (max-width: 991px){
    .space-blog {
        padding: 18%;
    }
}
@media (max-width: 767px){
    .space-blog {
        padding: 21%;
    }
    .imgBlog {
        height: 170px;
    }
}
@media (max-width: 575px){
    .space-blog {
        padding: 18vw;
    }
}

