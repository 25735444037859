.vsPortafolio {
    background: black;
}
#visPortafolio .portfolio-link {
    display: none;
}
#visPortafolio .portfolio-link {
	display: none;
}
#visPortafolio .slick-list {
    height: 500px; 
}
#visPortafolio {
    background: black;
    background-image: url('../../assets/images/Reticula-portafolio.svg');
    height: 100vh;
    overflow: auto;
}
#portafolioCarousel,.containerCarousel {
    height: 100%;
    position: relative;
}
.categoriesRow {
    position: absolute;
    bottom: 0;
    right: 35px;
    width: 630px;
}
.pt-2_0 {
    padding-top: 2.05rem !important;
}
#car {
    display: flex;
    align-items: center;
}
.yearDescription {
    text-align: center;
}
.divClientLogo {
	height: 118px;
    width: 60%;
    position: relative;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    align-items: center;
}
.slick-slide {
    pointer-events: none;
}
.slick-active {
    pointer-events: all;
}
.logo-carousel{
    height: 70px;
    width: 70px;
    position: absolute;
    left: 50%;
    top: -158px;
    transform: translateX(-50%);
    z-index: 99999999999;
}
.divClientLogo:after {
    content: '';
    width: 150px;
    height: 2px;
    background: gray;
    opacity: 0.7;
    position: absolute;
    display: flex;
    top: 50%;
    margin-left: -120px;
}
.divClientLogo:before {
    content: '';
    width: 150px;
    height: 2px;
    background: gray;
    opacity: 0.7;
    position: absolute;
    display: flex;
    top: 50%;
    margin-left: 120px;
}
.divClientLogo div{
	width: 70px;
	height: 70px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: 0.5s;
}
.divClientLogo div:after{
	content: '';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: gray;
	position: absolute;
    margin-left: 198px;
    display: flex;
    top: 46%;
}
._carousel_4y5gg_1 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.divClientLogo div:before{
	content: '';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: gray;
	position: absolute;
    margin-left: -198px;
    display: flex;
    top: 46%;
}
.portafolioHeight {
    height: auto;
}
.visualPortafolio {
    display: flex;
    align-items: center;
}
.visualPortafolio div {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 72%;
	width: 100%;
}
.slick-track,
.slick-slide,
.slick-slide > div,
.slick-slide > div > div,
.slick-slide > div > div > div{
	height: 100%;
}
.descriptionPortafolio {
	position: relative;
    display: flex;
}
.descriptionContainer {
	position: absolute;
    bottom: 70px;
    color: #d4d4d4;
    font-family: muli;
    width: 90%;
    padding-top: 14px;
}
.descriptionContainer p {
    font-size: 14px;
    padding-bottom: 3px;
}
.verBoton,
.verBoton:hover,
.verBoton:focus,
.verBoton:active {
	background-color: transparent !important;
    border-color: transparent !important;
    border: none;
    border-bottom: 1px solid white !important;
    border-radius: 0;
    margin-bottom: 18px;
    outline: none !important;
    box-shadow: none !important;
    float: right;
}
.categoriesPortafolio {
    float: right;
    color: #d4d4d4;
    padding-right: 3.3%;
    margin-bottom: 30px;
}
.descriptionContainer h3 {
        font-size: 18px;
    }
.categoriesPortafolio li{
    float: left;
    list-style:none;
    margin: 0 15px;
    font-size: 23px;
    font-family: saira;
    cursor: pointer;
}
.backgroundColorBlue {
    background-color: #338CBA;
    box-shadow: 0px 0px 10px 4px #338CBA;
}
.backgroundColorOrange {
    background-color: #C16315;
    box-shadow: 0px 0px 10px 4px #C16315;
}
.backgroundColorPurple {
    background-color: #8C0B60;
    box-shadow: 0px 0px 10px 4px #8C0B60;
}
.backgroundColorGreen {
    background-color: #87B037;
    box-shadow: 0px 0px 10px 4px #87B037;
}
.borderBottomColorBlue {
    border-bottom: 2px solid #338CBA;
}
.borderBottomColorOrange {
    border-bottom: 2px solid #C16315;
}
.borderBottomColorPurple {
    border-bottom: 2px solid #8C0B60;
}
.borderBottomColorGreen {
    border-bottom: 2px solid #87B037;
}
.borderColorBlue {
    border: 2px solid #338CBA;
}
.borderColorOrange {
    border: 2px solid #C16315;
}
.borderColorPurple {
    border: 2px solid #8C0B60;
}
.borderColorGreen {
    border: 2px solid #87B037;
}
.arrow-left {
    background-color: transparent;
    position: absolute;
    margin-left: -220px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 20px;
    border: transparent;
}
.descriptionContainer p {
    margin: 0;
}
.arrow-left:hover,.arrow-right:hover,.arrow-left:focus,.arrow-right:focus,.arrow-left:active,.arrow-right:active {
    background: transparent;
    background-color: transparent !important;
    border: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    outline: none;
    box-shadow: none !important;
}
.arrow-right {
    background-color: transparent;
    position: absolute;
    margin-left: 220px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 20px;
    border: transparent;
}
._carousel-wrapper_4y5gg_1 {
    overflow: hidden;
}
.modalImg {
    margin-top: 70px;
    height: 350px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px 10px 0 0;
}
#modalPortafolio .modal-content {
    padding: 20px;
    background-image: url('../../assets/images/Reticula-1.svg');
    background-size: cover;
}
#modalPortafolio .modal-content h1 {
    font-family: saira;
    margin-top: 20px;
    text-align: center;
}
#modalPortafolio .modal-body {
    padding:0px; 
    font-family: muli;
}
#modalPortafolio {
    max-width: 600px;
}
.dateModal {
    font-family: saira;
    text-align: center;
    position: relative;   
}
.logoModal {
    background-position: center;
    background-size: contain;
    height: 60px;
    width: 100px;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.logoModal:after {
    content: '';
    position: absolute;
    width: 120%;
    height: 3px;
    background: black;
    top: 50%;
    left: -120px;
}
.logoModal:before {
    content: '';
    position: absolute;
    width: 120%;
    height: 3px;
    background: black;
    top: 50%;
    left: 100px;    
}
.dateModal:after{
    content: '';
    position: absolute;
    width: 70px;
    height: 2px;
    background: black;
    left: 36%;
    transform: translate(-50%, -50%);
    top: 50%;
}
.dateModal:before{
    content: '';
    position: absolute;
    width: 70px;
    height: 2px;
    background: black;
    right: 24%;
    transform: translate(-50%, -50%);
    top: 50%;
}
.closeModal {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 8px;
    right: 20px;
    font-size: 20px;
}
.timesModal {
    cursor: pointer;
}
.embedModal {
    position: relative;
}
.play-pause {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 2px solid white;
    background: rgba(255, 255, 255, 0.3);
    width: 100px;
    height: 100px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.6s;
}
.play-button {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-left: 36px solid white;
    border-bottom: 20px solid transparent;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-43%, -50%);
    opacity: 1;
    transition: opacity 0.6s;
}
.refresh-icon {
    color: #000;
    position: absolute;
    margin-left: 4px;
    margin-top: 3px;
    width: 46px;
    height: 47px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border-top: solid 4px white;
    border-bottom: solid 4px white;
    border-left: solid 4px transparent;
    border-right: solid 4px white;
    transform: translate(-66% ,-50%);
    opacity: 0;
    transition: opacity 0.6s;
}
.refresh-icon:before {
    content: '';
    position: absolute;
    left: -3px;
    top: 28px;
    width: 13px;
    height: 13px;
    border-top: solid 3px white;
    border-left: solid 3px white;
    -webkit-transform: rotate(-22.5deg);
    transform: rotate(-22.5deg);
}
.play .play-button {
    opacity: 0;
}
.pause-button {
    height: 36px;
    width: 14px;
    background: white;
    border-radius: 2px;
    position: absolute;
    left: 47%;
    top: 50%;
    transform: translate(-100%, -50%);
    opacity: 0;
    transition: opacity 0.6s;
}
.pause-button:after {
    content: "";
    height: 36px;
    width: 14px;
    background: white;
    border-radius: 2px;
    position: absolute;
    left: 26px;
    transform: translate(-50%, -50%);
    top: 50%;
}
.play .pause-button {
    opacity: 1;
}
.hide-button {
    opacity: 0;
}
.refresh.icon {
  color: #000;
  position: absolute;
  margin-left: 4px;
  margin-top: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-top: solid 1px currentColor;
  border-bottom: solid 1px currentColor;
  border-left: solid 1px transparent;
  border-right: solid 1px currentColor;
}
.refresh.icon:before {
  content: '';
  position: absolute;
  left: 1px;
  top: 10px;
  width: 3px;
  height: 3px;
  border-top: solid 1px currentColor;
  border-left: solid 1px currentColor;
  -webkit-transform: rotate(-22.5deg);
          transform: rotate(-22.5deg);
}
@media(max-width: 991px) {
    .descriptionPortafolio {
        position: relative;
        display: flex;
        justify-content: center;
    }
    .categoriesPortafolio li {
        font-size: 20px;
    }
    .descriptionContainer h3 {
        font-size: 18px;
    }
    .descriptionContainer{
        width: 90%;
        margin: 0 auto;
        height: 180px;
    }
    .yearDescription {
        padding: 0px;
    }
}
@media(max-width: 767px) {
    .descriptionContainer {
        width: 80%;
        margin: 0 auto;
        height: 190px; 
        margin-top: 28px;
        top: 0;
    }
    .titlesDescription {
        height: 12px;
    }
    .yearDescription {
        text-align: left;
    }
    .descriptionPortafolio {
        margin-left: 0px;
    }
    .descriptionPortafolio {
        justify-content: center;
    }
    .divClientLogo div:after{
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: gray;
        position: absolute;
        margin-left: 145px;
        display: flex;
        top: 46%;
    }
    .yearDescription {
        padding-left: 15px;
        padding-right: 15px;
    }
    .divClientLogo div:before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: gray;
        position: absolute;
        margin-left: -145px;
        display: flex;
        top: 46%;
    }
    .divClientLogo:before {
        content: '';
        width: 95px;
        height: 2px;
        background: gray;
        opacity: 0.7;
        position: absolute;
        display: flex;
        top: 50%;
        margin-left: 95px;
    }
    .divClientLogo:after {
        content: '';
        width: 95px;
        height: 2px;
        background: gray;
        opacity: 0.7;
        position: absolute;
        display: flex;
        top: 50%;
        margin-left: -95px;
    }
    .arrow-right{
        margin-left: 160px;
    }
    .arrow-left {
        margin-left: -160px;
    }
    .pt-2_0 {
        padding: 0 !important;
    }
}
@media(max-width: 575px){
    .categoriesPortafolio li {
        font-size: 13px;
        margin: 0px 8px;
    }
    .descriptionContainer h3 {
        font-size: 21px;
    }
    .categoriesPortafolio {
        padding: 0;
    }
    .categoriesWrap {
        justify-content: center;
        display: flex;
    }
    .categoriesRow {
        position: absolute;
        bottom: 0;
        left: 54%;
        transform: translateX(-50%);
        width: 320px;
    }
    .divClientLogo div:after{
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: gray;
        position: absolute;
        margin-left: 100px;
        display: flex;
        top: 46%;
    }
    .divClientLogo div:before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: gray;
        position: absolute;
        margin-left: -100px;
        display: flex;
        top: 46%;
    }
    .divClientLogo:before {
        content: '';
        width: 60px;
        height: 2px;
        background: gray;
        opacity: 0.7;
        position: absolute;
        display: flex;
        top: 50%;
        margin-left: 70px;
    }   
    .divClientLogo:after {
        content: '';
        width: 60px;
        height: 2px;
        background: gray;
        opacity: 0.7;
        position: absolute;
        display: flex;
        top: 50%;
        margin-left: -70px;
    }    
    .arrow-right{
        margin-left: 115px;
    }
    .arrow-left {
        margin-left: -115px;
    }
}
